import React, { useState, useMemo } from "react";
import './home.css';
import TiltCard from './TiltCard';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import raiderBox from './pages/design/raiders/RaidersBox.png';
// Photo imports
import mch1 from './pages/photo/music/mch1.jpg';
import duck2 from './pages/photo/wildlife/duck2.jpg';
import purple from './pages/photo/nike/purple.jpg';
import hi23 from './pages/photo/hawaii/hi23.jpg';
import bs22 from './pages/photo/borrego/bs22.jpg';
import swov1 from './pages/photo/swoveralls/DSC03113.jpg';

// Art imports
import star from './pages/art/treehouse/star.jpg';
import home from './pages/art/home and city/time in a tree.jpg';
import ego from './pages/art/memory/ego death.jpg';
import wanderer from './pages/art/misc/wanderer.png';

// Import images
const images = {
    mch1, duck2, purple, hi23, bs22,
    star, home, ego, wanderer, raiderBox,
    'psyduck2.jpg': '/psyduck2.jpg',
    'gecko3.jpg': '/gecko3.jpg',
    'denim+.jpg': '/denim+.jpg',
    'swoveralls.jpg': '/swoveralls.jpg',
    'thinkingCapLogo.png': '/thinkingCapLogo.png',
    'havit.png': '/havit.png',
    'lighten.jpg': '/lighten.jpg',
    'spotify-4.jpg': '/spotify-4.jpg',
    'musLogo.png': '/musLogo.png'

};

const projects = [
    // Design Projects
    {
        to: '/projects/mus',
        title: 'Müs',
        date: '2025',
        imageSrc: images['musLogo.png'],
        imageAlt: 'Mus Logo',
        tags: ['Product', 'UI/UX']
    },
    {
        to: '/projects/raiders',
        title: 'Raiders of the Abyss',
        date: '2025',
        imageSrc: images['raiderBox'],
        imageAlt: 'Raiders Logo',
        tags: ['Product']
    },
    {
        to: '/projects/swoveralls-cart',
        title: 'Cart Redesign',
        date: '2024',
        imageSrc: images['swoveralls.jpg'],
        imageAlt: 'Swoveralls Cart',
        tags: ['UI/UX']
    },
    {
        to: '/projects/thinkingcap',
        title: 'Coding Storybooks',
        date: '2022',
        imageSrc: images['thinkingCapLogo.png'],
        imageAlt: 'Thinking Cap Logo',
        tags: ['Brand', 'Illustration']
    },
    {
        to: '/projects/spirit',
        title: 'Spirit',
        date: '2022',
        imageSrc: images['havit.png'],
        imageAlt: 'Spirit Project',
        tags: ['Product', 'UI/UX']
    },
    {
        to: '/projects/lighten',
        title: 'Lighten',
        date: '2022',
        imageSrc: images['lighten.jpg'],
        imageAlt: 'Lighten Project',
        tags: ['Brand', 'Product']
    },
    {
        to: '/projects/spotify',
        title: 'Music Inbox',
        date: '2021',
        imageSrc: images['spotify-4.jpg'],
        imageAlt: 'Music Inbox',
        tags: ['Product', 'UI/UX']
    },
    // Photography Projects
    {
        to: '/photo/swoveralls',
        title: 'Swoveralls',
        date: '2024',
        imageSrc: swov1,
        imageAlt: 'Swoveralls',
        tags: ['Photo', 'Fashion']
    },
    {
        to: '/photo/concerts',
        title: 'Concerts',
        date: '2023',
        imageSrc: mch1,
        imageAlt: 'Concert Photography',
        tags: ['Photo']
    },
    {
        to: '/photo/wildlife',
        title: 'Wildlife',
        date: '2021',
        imageSrc: duck2,
        imageAlt: 'Wildlife Photography',
        tags: ['Photo']
    },
    {
        to: '/photo/swoosh',
        title: "Swoo's and Hues",
        date: '2020',
        imageSrc: purple,
        imageAlt: 'Nike Photography',
        tags: ['Photo', 'Brand']
    },
    {
        to: '/photo/hawaii',
        title: 'Hawaii',
        date: '2021',
        imageSrc: hi23,
        imageAlt: 'Hawaii Photography',
        tags: ['Photo']
    },
    {
        to: '/photo/borrego',
        title: 'Borrego Springs',
        date: '2023',
        imageSrc: bs22,
        imageAlt: 'Borrego Springs Photography',
        tags: ['Photo']
    },
    // Art Projects
    {
        to: '/art/treehouse',
        title: 'Treehouse Collection',
        date: '2020',
        imageSrc: star,
        imageAlt: 'Treehouse Art',
        tags: ['Art', 'Illustration']
    },
    {
        to: '/art/homeandcity',
        title: 'Home and City',
        date: '2020',
        imageSrc: home,
        imageAlt: 'Home and City Art',
        tags: ['Art', 'Illustration']
    },
    {
        to: '/art/memory',
        title: 'Memory',
        date: '2021',
        imageSrc: ego,
        imageAlt: 'Memory Art Collection',
        tags: ['Art', 'Illustration']
    },
    {
        to: '/art/misc',
        title: 'Misc. Art',
        date: '2023',
        imageSrc: wanderer,
        imageAlt: 'Miscellaneous Art',
        tags: ['Art', 'Illustration']
    },
    // Fashion Projects
    {
        to: '/fashion/psyduck-hat',
        title: 'Psyduck Bucket Hat',
        date: '2022',
        imageSrc: images['psyduck2.jpg'],
        imageAlt: 'Psyduck Bucket Hat',
        tags: ['Fashion',]
    },
    {
        to: '/fashion/culture-jacket',
        title: 'Third Culture Jacket',
        date: '2022',
        imageSrc: images['gecko3.jpg'],
        imageAlt: 'Third Culture Jacket',
        tags: ['Fashion', 'Art']
    },
    {
        to: '/fashion/sunset-denim',
        title: 'Sunset Denim',
        date: '2021',
        imageSrc: images['denim+.jpg'],
        imageAlt: 'Sunset Denim',
        tags: ['Fashion', 'Art']
    },

];

function Home() {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');
    const [activeFilter, setActiveFilter] = useState('All');

    const openLightbox = (imageSrc) => {
        setCurrentImage(imageSrc);
        setOpen(true);
    };

    // Get unique tags from all projects
    const allTags = useMemo(() => {
        const tags = new Set(['All']);
        projects.forEach(project => {
            project.tags.forEach(tag => tags.add(tag));
        });
        return Array.from(tags);
    }, []);

    // Filter projects based on active filter
    const filteredProjects = useMemo(() => {
        if (activeFilter === 'All') return projects;
        return projects.filter(project => project.tags.includes(activeFilter));
    }, [activeFilter]);

    return (
            <div className="home">
                <div className="section intro">
                    <div className="section flex spaced">
                        <div className="section flex text">
                            <img id="nameTitle" src="nameTitle.png"></img>
                            <p>A creator with a mission to inspire <br></br> <span className="greenText">Empathy</span>, <span className="blueText">Confidence</span>, and <span className="purpleText">Mindfulness</span></p>
                        </div>
                        <div className="section flex image">
                            <img id="threeLights" src="threeLights.png"></img>
                        </div>
                    </div>
                </div>
                <div className="section projects">
                    <img id="projectsTitle" src="projectsTitle.png"></img>
                    <div className="filter-buttons">
                        {allTags.sort((a, b) => a === 'All' ? -1 : b === 'All' ? 1 : a.localeCompare(b)).map(tag => (
                            <button
                                key={tag}
                                className={`filter-button ${tag.toLowerCase().replace('/', '-')} ${activeFilter === tag ? 'active' : ''}`}
                                onClick={() => setActiveFilter(tag)}
                            >
                                {tag}
                            </button>
                        ))}
                    </div>
                    <div className="section flex lightbox">
                        {/* <img id="projectLight" src="projectLight.png"></img> */}
                        {/* <div className="home-lightbar green"></div> */}
                    <div className="section flex cards">
                        {filteredProjects.map(project => {
                            const isExternalLink = project.to.startsWith('http');
                            return (
                                <TiltCard
                                    key={project.to}
                                    {...project}
                                    onImageClick={openLightbox}
                                    backgroundColor={project.tags[0].toLowerCase().replace('/', '-')}
                                    target={isExternalLink ? '_blank' : undefined}
                                    rel={isExternalLink ? 'noopener noreferrer' : undefined}
                                />
                            );
                        })}
                        <Lightbox
                            open={open}
                            close={() => setOpen(false)}
                            slides={[{ src: currentImage }]}
                        />
                        </div>
                    </div>
                    
                </div>
                <div className="section projects">
                    <img id="aboutTitle" src="aboutTitle.png"></img>
                    <div className="section flex lightbox">
                        {/* <img id="designLight" src="designLight.png"></img> */}
                        <div className="home-lightbar blue"></div>
                        <div className="section flex about">
                            <a> I believe that your life is the sum of all the experiences you get to encounter. Every bite of food, every person you meet, or every product designer's aesthetic, personal website you have the pleasure of visiting is an experience that can help you grow if you let it. Fear is the biggest obstacle for seeking out and learning from new experiences, though. I dream of designing experiences that help people fight their fear by inspiring mindfulness, confidence, and empathy.</a>
                            <br></br>
                            <a><span className="purpleText"><b>Mindfulness</b></span> allows you to recognize your fears and find opportunities for new experiences.</a>
                            <br></br>
                            <a><span className="blueText"><b>Confidence</b></span> enables you to push past your fears and face any challenge or experience head on.</a>
                            <br></br>
                            <a><span className="greenText"><b>Empathy</b></span> lets you understand another person's experience and share one with them.</a>
                            <br></br>
                            <a>As a self-taught, multidisciplinary designer, I take pride in using these principles to find unique solutions and inspiration from the world around me.   </a>
                        </div>
                    </div>
                </div>
                <div className="section projects">
                    <img id="contactTitle" src="contactTitle.png"></img>
                    <div className="section flex contact">
                        {/* <img id="fashionLight" src="fashionLight.png"></img> */}
                        <div className="home-lightbar purple"></div>
                        <div className="section flex about">
                            <a> I am currently looking to embark on new adventures. If you are interested in working with me, you can view my resume below and contact me via email.</a>
                            <br></br>
                            <a className="purpleTextHover" href="ShivaVResume2025.pdf" target="_blank"><u>Resume</u></a>
                            <a className="purpleTextHover" href="mailto: shivatejav@gmail.com" >shivatejav@gmail.com</a>
                            <br></br>
                            <br></br>
                            <a>Check out my Instagram accounts:</a>
                            <a className="greenTextHover" href="https://www.instagram.com/shivatejav/?hl=en" target="_blank">@shivatejav</a>
                            <a className="blueTextHover" href="https://www.instagram.com/svshoots/?hl=en" target="_blank">@svshoots</a>
                            <a className="purpleTextHover" href="https://www.instagram.com/designed.by.shiva/?hl=en" target="_blank">@designed.by.shiva</a>
                        </div>
                    </div>
                </div>
            </div>
        );
}

export default Home;