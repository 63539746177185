import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './raiders.css';

import raidersBox from './raiders/RaidersBox.png';
import raidersCards from './raiders/raidersCards.png';

export class Raiders extends Component {

    render() {


        return (
            <div className="lightenPage">
            <div className="title">
                <h2>Raiders of the Abyss</h2>
                <br></br>
            </div>
            <div className="subtitle">
                <h3>Overview</h3>
                <br></br>
                
            </div>
            <div className='introd'>
                <a>Raiders of the Abyss is a 2-4 player battle royale card game that was made with the help of AI. From simulating games to balance card effects and mechanics, to generating the artwork for the cards, the AI helped me bring my vision to life better than I thought possible. </a> 
                <br></br>
                <br></br>
                <img className="raidImg" id="raidBox" src={raidersBox} ></img>
                <img className="raidImg" id="raidersCards" src={raidersCards} ></img>
                <br></br>
                <br></br>
            </div>
           
           
        </div>
        )
    }
}

export default Raiders