import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './memory.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import butterfly from './memory/butterflyEffect.jpg';
import ego from './memory/ego death.jpg';


const Memory = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: butterfly, id: 'butterfly', alt: 'Butterfly Effect' },
        { src: ego, id: 'ego', alt: 'Ego Death' }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={image.alt}
                        id={image.id}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Memory