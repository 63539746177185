import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './playlist.css';

const images = {
    'dreamscape.png': '/dreamscape.png',
    'break.png': '/break.png',
    'honeyLavender.png': '/honeyLavender.png',
    'jacuzziJoints.png': '/jacuzziJoints.png',
    'neonNights.png': '/neonNights.png',
    'lunar.png': '/lunar.png'
};


export class Playlist extends Component {
    render() {
        const playlists = [
            {
                href: 'https://open.spotify.com/playlist/5O72pAJoOfJSKcKsy1GgrT?si=10649d3d1ed7467f',
                title: 'Dreamscape',
                imageId: 'dreamscapeCard',
                imageSrc: images['dreamscape.png'],
                description: ''
            },
            {
                href: 'https://open.spotify.com/playlist/2ewHpbvHJnC5y8hv1EPQEX?si=a8ba8cc01c6341c1',
                title: 'BREAK!',
                imageId: 'breakCard',
                imageSrc: images['break.png'],
                description: 'Disrupt your rhythm.',
                contrast: true
            },
            {
                href: 'https://open.spotify.com/playlist/5g1JfgFSwuaie2Y5KrxuPC?si=8343ac23a4c84e07',
                title: 'Honey Lavender',
                imageId: 'honeyCard',
                imageSrc: images['honeyLavender.png'],
                description: 'What follows the golden hour.'
            },
            {
                href: 'https://open.spotify.com/playlist/4hc3AGvdacuxfpti9eGuyx?si=b3c74e5feafb4cc9',
                title: 'Jacuzzi Joints',
                imageId: 'jacuzziCard',
                imageSrc: images['jacuzziJoints.png'],
                description: ''
            },
            {
                href: 'https://open.spotify.com/playlist/0VsPScifQ8R4S0f6C4YBDg?si=a0052dd06c8a4aaf',
                title: 'Neon Nights',
                imageId: 'neonCard',
                imageSrc: images['neonNights.png'],
                description: ''
            },
            {
                href: 'https://open.spotify.com/playlist/5hWoaVsFcfCqeH5EBcnVcK?si=cc872ea0bbec47db',
                title: 'Lunar',
                imageId: 'lunarCard',
                imageSrc: images['lunar.png'],
                description: 'To the moon and back.'
            }
        ];

        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="playlistTitle" src="playlistTitle.png" alt="Playlist Title" />
                        <a>Check out my Spotify playlists.</a>
                    </div>

                    <div className="section flex lightbox">
                        <div className="lightbar pink leftbar"></div>
                        <div className="section flex cards">
                            {playlists.map(playlist => (
                                <a key={playlist.href} className="tilt" href={playlist.href} target="_blank" rel="noopener noreferrer">
                                    {[...Array(9)].map((_, i) => (
                                        <span key={i} className="t_over"></span>
                                    ))}
                                    <div className="card rose-background">
                                        <div className="card-title">
                                            <p className={`project-title${playlist.contrast ? ' contrast' : ''}`}>{playlist.title}</p>
                                        </div>
                                        <img className="card-image" id={playlist.imageId} src={playlist.imageSrc} alt={playlist.title} />
                                        {playlist.description && (
                                            <div className="card-text">
                                                <a>{playlist.description}</a>
                                            </div>
                                        )}
                                    </div>
                                </a>
                            ))}
                        </div>
                        <div className="lightbar pink rightbar"></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Playlist;
