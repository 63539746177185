import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './misc.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import wanderer from './misc/wanderer.png';
import seek from './misc/seek.png';
import seeking from './misc/seeking.png';
import silentPrincess from './misc/silent princess.png';
import staticSnow from './misc/static snow.png';
import telescoped from './misc/telescoped.png';



const Misc = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: wanderer, id: 'wanderer', alt: 'Big Steppa' },
        { src: seek, id: 'seek', alt: 'Seeker' },
        { src: seeking, id: 'seeking', alt: 'Seeking' },
        { src: silentPrincess, id: 'silentPrincess', alt: 'Silent Princess' },
        { src: staticSnow, id: 'staticSnow', alt: 'Static Snow' },
        { src: telescoped, id: 'telescoped', alt: 'Star Crossed' }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={image.alt}
                        id={image.id}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Misc