import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './borrego.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bs1 from './borrego/bs1.jpg';
import bs2 from './borrego/bs2.jpg';
import bs3 from './borrego/bs3.jpg';
import bs4 from './borrego/bs4.jpg';
import bs5 from './borrego/bs5.jpg';
import bs6 from './borrego/bs6.jpg';
import bs7 from './borrego/bs7.jpg';
import bs8 from './borrego/bs8.jpg';
import bs9 from './borrego/bs9.jpg';
import bs10 from './borrego/bs10.jpg';
import bs11 from './borrego/bs11.jpg';
import bs12 from './borrego/bs12.jpg';
import bs13 from './borrego/bs13.jpg';
import bs14 from './borrego/bs14.jpg';
import bs15 from './borrego/bs15.jpg';
import bs16 from './borrego/bs16.jpg';
import bs17 from './borrego/bs17.jpg';
import bs18 from './borrego/bs18.jpg';
import bs19 from './borrego/bs19.jpg';
import bs20 from './borrego/bs20.jpg';
import bs21 from './borrego/bs21.jpg';
import bs22 from './borrego/bs22.jpg';
import bs23 from './borrego/bs23.jpg';
import bs24 from './borrego/bs24.jpg';
import bs25 from './borrego/bs25.jpg';
import bs26 from './borrego/bs26.jpg';
import bs27 from './borrego/bs27.jpg';
import bs28 from './borrego/bs28.jpg';
import bs29 from './borrego/bs29.jpg';





const Borrego = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: bs1 }, { src: bs2 }, { src: bs3 }, { src: bs4 },
        { src: bs5 }, { src: bs6 }, { src: bs7 }, { src: bs8 },
        { src: bs9 }, { src: bs10 }, { src: bs11 }, { src: bs12 },
        { src: bs13 }, { src: bs14 }, { src: bs15 }, { src: bs16 },
        { src: bs17 }, { src: bs18 }, { src: bs19 }, { src: bs20 },
        { src: bs21 }, { src: bs22 }, { src: bs23 }, { src: bs24 },
        { src: bs25 }, { src: bs26 }, { src: bs27 }, { src: bs28 },
        { src: bs29 }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={`Borrego Springs ${idx + 1}`}
                        id={`bs${idx + 1}`}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Borrego