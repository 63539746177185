import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './musicInbox/Montserrat/Montserrat-Bold.ttf';
import './musicInbox/Montserrat/Montserrat-Regular.ttf';
import './musicInbox.css';
import './lighten.css';
import './mus.css';

import musHome from './mus/mus home.png';
import musProfile from './mus/mus profile.png';
import musPlaylist from './mus/mus playlist.png';
import musSearch from './mus/mus search.png';

export class Mus extends Component {

    render() {


        return (
                <div className="lightenPage">
                    <div className="title">
                        <h2>Müs: In Development</h2>
                        <br></br>
                    </div>
                    <div className="subtitle">
                        <h3>Overview</h3>
                        <br></br>
                        
                    </div>
                    <div className='introd'>
                        <a>Müs is a music playlist discovery platform that I am currently developing. It allows users to connect their Spotify playlists and publish them for the world to access. Using many recommendation factors, the app displays a range of playlists to tailor to the user's interests. I develop the frontend of the application as well as design the user experience and brand aesthetic.</a> 
                        <br></br>
                        <br></br>
                        <img className="musImg" id="musHome" src={musHome} ></img>
                        <img className="musImg" id="musProfile" src={musProfile} ></img>
                        <img className="musImg" id="musPlaylist" src={musPlaylist} ></img>
                        <img className="musImg" id="musSearch" src={musSearch} ></img>
                        <br></br>
                        <br></br>
                    </div>
                   
                   
                </div>
        )
    }
}

export default Mus