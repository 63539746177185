import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './homeandcity.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import home from './home and city/time in a tree.jpg';
import city from './home and city/city.jpg';


const Homeandcity = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: home, alt: "Time in a Tree" },
        { src: city, alt: "Neon Nights" }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={image.alt}
                        id={idx === 0 ? 'home' : 'city'}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Homeandcity