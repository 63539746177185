import React from 'react';
import { Link } from 'react-router-dom';
import './pages/design.css';

function TiltCard({ 
    to, 
    title, 
    date, 
    imageSrc, 
    imageAlt, 
    onImageClick,
    tags = [],
    backgroundColor = 'blue' // default to blue background
}) {
    return (
        <Link to={to}>
            <div className="tilt">
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <span className="t_over" />
                <div className={`card ${backgroundColor}-background`}>
                    <div className="card-title">
                        <p className="project-title">{title}</p>
                        <p className="project-date">{date}</p>
                    </div>
                    <img 
                        className="card-image" 
                        id="testCard" 
                        src={imageSrc} 
                        alt={imageAlt}
                        onClick={(e) => {
                            e.preventDefault(); // Prevent navigation when clicking image
                            onImageClick && onImageClick(imageSrc);
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    <div className="card-text">
                        {tags.map((tag, index) => (
                            <span key={index} className={tag.toLowerCase().replace('/', '-')}>{tag}</span>
                        ))}
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default TiltCard;
