import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import './design.css';
import TiltCard from '../TiltCard';


function Design() {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState('');

    const openLightbox = (imageSrc) => {
        setCurrentImage(imageSrc);
        setOpen(true);
    };

    return (
        <div>
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="designTitle" src="designTitle.png" alt="Design Title" />
                    </div>
                    <div className="section flex lightbox">
                        <div className="lightbar blue leftbar" />
                        <div className="section flex cards">
                            <TiltCard
                                to="projects/swoveralls-cart"
                                title="Swoveralls Cart Redesign"
                                date="2024"
                                imageSrc="swoveralls.jpg"
                                imageAlt="Swoveralls Cart"
                                onImageClick={openLightbox}
                                tags={['UI/UX']}
                            />

                            <TiltCard
                                to="projects/spirit"
                                title="Spirit"
                                date="2022"
                                imageSrc="havit.png"
                                imageAlt="Spirit Project"
                                onImageClick={openLightbox}
                                tags={['Product', 'UI/UX']}
                            />
                                
                            <TiltCard
                                to="projects/lighten"
                                title="Lighten"
                                date="2022"
                                imageSrc="lighten.jpg"
                                imageAlt="Lighten Project"
                                onImageClick={openLightbox}
                                tags={['Brand', 'Product']}
                            />

                            <TiltCard
                                to="projects/thinkingcap"
                                title="Coding Storybooks"
                                date="2022"
                                imageSrc="thinkingCapLogo.png"
                                imageAlt="Thinking Cap Logo"
                                onImageClick={openLightbox}
                                tags={['Brand', 'Illustration']}
                            />

                            <TiltCard
                                to="projects/spotify"
                                title="Music Inbox"
                                date="2021"
                                imageSrc="spotify-4.jpg"
                                imageAlt="Music Inbox"
                                onImageClick={openLightbox}
                                tags={['Product', 'UI/UX']}
                            />
                            <Lightbox
                                open={open}
                                close={() => setOpen(false)}
                                slides={[{ src: currentImage }]}
                            />
                        </div>
                        <div className="lightbar blue rightbar" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Design
