import React, { Component } from 'react'
import { Link } from 'react-router-dom';
// import './art.css';
import star from './art/treehouse/star.jpg';
import home from './art/home and city/time in a tree.jpg';
import ego from './art/memory/ego death.jpg';
import wanderer from './art/misc/wanderer.png';

const images = {
    star, home, ego, wanderer
};


export class Art extends Component {
    render() {
        return (
            <div className="home">
                <div className="section design">
                    <div className="section title-text">
                        <img className="pageTitle" id="artTitle" src="artTitle.png"></img>
                        {/* <a>These are the art projects.</a> */}
                    </div>

                
                    <div className="section flex lightbox">
                        {/* <img className="pageLight" id="artLight" src="artLight.png"></img> */}
                        <div className="lightbar art leftbar"></div>
                        <div className="section flex cards">
                        <Link to='/art/treehouse'>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card art-background">
                                    <div className="card-title">
                                        <p className="project-title">Treehouse Collection</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="treehouseCard" src={star}></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to='/art/homeandcity'>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card art-background">
                                    <div className="card-title">
                                        <p className="project-title">Home and City</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="homecityCard" src={home}></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to='/art/memory'>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card art-background">
                                    <div className="card-title">
                                        <p className="project-title contrast">Memory</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="memoryCard" src={ego}></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to='/art/misc'>
                            <div className="tilt">
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <span className="t_over"></span>
                                <div className="card art-background">
                                    <div className="card-title">
                                        <p className="project-title">Miscellaneous</p>
                                        {/* <p>2022</p> */}
                                    </div>
                                    <img className="card-image" id="miscCard" src={wanderer}></img>
                                    <div className="card-text">
                                        {/* <a>This is a placeholder sentence for a project description.</a> */}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        </div>
                        {/* <img className="pageLight" id="artLight" src="artLight.png"></img> */}
                        <div className="lightbar art rightbar"></div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Art
