import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './wildlife.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import bird1 from './wildlife/bird1.jpg';
import bird2 from './wildlife/bird2.jpg';
import bird3 from './wildlife/bird3.jpg';
import cat1 from './wildlife/cat1.jpg';
import cat2 from './wildlife/cat2.jpg';
import coyote from './wildlife/coyote.jpg';
import deer1 from './wildlife/deer1.jpg';
import deer2 from './wildlife/deer2.jpg';
import deer3 from './wildlife/deer3.jpg';
import duck1 from './wildlife/duck1.jpg';
import duck2 from './wildlife/duck2.jpg';
import duck3 from './wildlife/duck3.jpg';
import duck4 from './wildlife/duck4.jpg';
import fish from './wildlife/fish.jpg';
import fox from './wildlife/fox.jpg';
import gecko1 from './wildlife/gecko1.jpg';
import gull from './wildlife/gull.jpg';
import lizard2 from './wildlife/lizard2.jpg';
import turtle from './wildlife/turtle.jpg';


const Wildlife = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: duck1 }, { src: cat1 }, { src: cat2 }, { src: duck3 },
        { src: duck2 }, { src: duck4 }, { src: turtle }, { src: lizard2 },
        { src: gecko1 }, { src: gull }, { src: fish }, { src: fox },
        { src: bird1 }, { src: bird2 }, { src: coyote }, { src: bird3 },
        { src: deer1 }, { src: deer2 }, { src: deer3 }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt="wildlife"
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
};

export default Wildlife