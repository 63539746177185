import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './duckCamo.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import red1 from './nike/red1.jpg';




const DuckCamo = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        // Add your images here in the format: { src: imageVariable }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt="duck camo"
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default DuckCamo