import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './hawaii.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import hi1 from './hawaii/hi1.jpg';
import hi2 from './hawaii/hi2.jpg';
import hi3 from './hawaii/hi3.jpg';
import hi4 from './hawaii/hi4.jpg';
import hi5 from './hawaii/hi5.jpg';
import hi6 from './hawaii/hi6.jpg';
import hi7 from './hawaii/hi7.jpg';
import hi8 from './hawaii/hi8.jpg';
import hi9 from './hawaii/hi9.jpg';
import hi10 from './hawaii/hi10.jpg';
import hi11 from './hawaii/hi11.jpg';
import hi12 from './hawaii/hi12.jpg';
import hi13 from './hawaii/hi13.jpg';
import hi14 from './hawaii/hi14.jpg';
import hi15 from './hawaii/hi15.jpg';
import hi16 from './hawaii/hi16.jpg';
import hi17 from './hawaii/hi17.jpg';
import hi18 from './hawaii/hi18.jpg';
import hi19 from './hawaii/hi19.jpg';
import hi20 from './hawaii/hi20.jpg';
import hi21 from './hawaii/hi21.jpg';
import hi22 from './hawaii/hi22.jpg';
import hi23 from './hawaii/hi23.jpg';




const Hawaii = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: hi1 }, { src: hi2 }, { src: hi3 }, { src: hi4 },
        { src: hi7 }, { src: hi8 }, { src: hi9 }, { src: hi10 },
        { src: hi11 }, { src: hi15 }, { src: hi21 }, { src: hi16 },
        { src: hi17 }, { src: hi18 }, { src: hi20 }, { src: hi22 },
        { src: hi12 }, { src: hi13 }, { src: hi19 }, { src: hi14 },
        { src: hi5 }, { src: hi6 }, { src: hi23 }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={`Hawaii ${idx + 1}`}
                        id={`hi${idx + 1}`}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Hawaii