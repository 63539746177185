import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './nike.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import red1 from './nike/red1.jpg';
import red2 from './nike/red2.jpg';
import orange from './nike/orange.jpg';
import yellow from './nike/yellow.jpg';
import green from './nike/green.jpg';
import blue from './nike/blue.jpg';
import purple from './nike/purple.jpg';
import black from './nike/blackandwhite.jpg';



const Nike = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: red1, id: 'red1' },
        { src: red2, id: 'red2' },
        { src: orange, id: 'orange' },
        { src: yellow, id: 'yellow' },
        { src: green, id: 'green' },
        { src: black, id: 'black' },
        { src: blue, id: 'blue' },
        { src: purple, id: 'purple' }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={`Nike ${image.id}`}
                        id={image.id}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Nike