import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './treehouse.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import treehousepic from './treehouse/treehouse.jpg';
import star from './treehouse/star.jpg';
import light from './treehouse/light.jpg';


const Treehouse = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: treehousepic, id: 'treehousepic', alt: 'Treehouse' },
        { src: star, id: 'star', alt: 'North Star' },
        { src: light, id: 'light', alt: 'Chandelier' }
    ];

    return (
        <>
            <div className="gallery treehouse">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={image.alt}
                        id={image.id}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Treehouse