import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'yet-another-react-lightbox/styles.css';
import './App.css';
import Header from './components/header.js';
import Home from './components/home.js';

import Design from './components/pages/design.js';
  import MusicInbox from './components/pages/design/musicInbox.js';
  import ThinkingCap from './components/pages/design/thinkingcap.js';
  import Lighten from './components/pages/design/lighten.js';
  import Havit from './components/pages/design/havit.js';
  import Moonwalkers from './components/pages/design/moonwalkers-lp.js';
  import SwovCart from './components/pages/design/swov-cart.js';
  import SwovGift from './components/pages/design/swov-gift.js';
  import SwovLP from './components/pages/design/swov-lp.js';
  import SwovMarketing from './components/pages/design/swov-marketing.js';
  import SwovPins from './components/pages/design/swov-pins.js';
import Art from './components/pages/art.js';
  import Treehouse from './components/pages/art/treehouse.js';
  import Homeandcity from './components/pages/art/homeandcity.js';
  import Memory from './components/pages/art/memory';
  import Misc from './components/pages/art/misc.js';
import Fashion from './components/pages/fashion.js';
  import DuckCamo from './components/pages/Fashion/duckCamo.js';
  import EgoDeath from './components/pages/Fashion/egoDeath.js';
  import Festivalls from './components/pages/Fashion/festivalls.js';
  import Gecko from './components/pages/Fashion/gecko.js';
  import Kage from './components/pages/Fashion/kage.js';
  import Psyduck from './components/pages/Fashion/psyduck.js';
  import RetroSummer from './components/pages/Fashion/retroSummer.js';
  import SunsetDenim from './components/pages/Fashion/sunsetDenim.js';
import Photo from './components/pages/photo.js';
  import Concerts from './components/pages/photo/concerts.js';
  import Wildlife from './components/pages/photo/wildlife.js';
  import Hawaii from './components/pages/photo/hawaii.js';
  import Nike from './components/pages/photo/nike.js';
  import Borrego from './components/pages/photo/borrego.js';
  import Swoveralls from './components/pages/photo/swoveralls.js';
import Video from './components/pages/video.js';
import Music from './components/pages/music.js';
import Playlist from './components/pages/playlist.js';
import Stories from './components/pages/stories.js';
import Mus from './components/pages/design/mus.js';
import Raiders from './components/pages/design/raiders.js';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          
          {/* Projects Routes */}
          <Route path="projects">
            <Route index element={<Design />} />
            <Route path="spotify" element={<MusicInbox />} />
            <Route path="thinkingcap" element={<ThinkingCap />} />
            <Route path="lighten" element={<Lighten />} />
            <Route path="spirit" element={<Havit />} />
            <Route path="moonwalkers" element={<Moonwalkers />} />
            <Route path="swoveralls-cart" element={<SwovCart />} />
            <Route path="swoveralls-gift-card" element={<SwovGift />} />
            <Route path="swoveralls-landing-pages" element={<SwovLP />} />
            <Route path="swoveralls-marketing" element={<SwovMarketing />} />
            <Route path="swoveralls-pins" element={<SwovPins />} />
            <Route path="mus" element={<Mus />} />
            <Route path="raiders" element={<Raiders />} />
          </Route>

          {/* Art Routes */}
          <Route path="art">
            <Route index element={<Art />} />
            <Route path="treehouse" element={<Treehouse />} />
            <Route path="homeandcity" element={<Homeandcity />} />
            <Route path="memory" element={<Memory />} />
            <Route path="misc" element={<Misc />} />
          </Route>

          {/* Fashion Routes */}
          <Route path="fashion">
            <Route index element={<Fashion />} />
            <Route path="sunset-denim-jacket" element={<SunsetDenim />} />
            <Route path="psyduck-bucket-hat" element={<Psyduck />} />
            <Route path="ego-death-denim-jacket" element={<EgoDeath />} />
            <Route path="kage-sling-bag" element={<Kage />} />
            <Route path="gecko-jacket" element={<Gecko />} />
            <Route path="festivalls" element={<Festivalls />} />
            <Route path="retro-summer-swoveralls" element={<RetroSummer />} />
            <Route path="duck-camo-swoveralls" element={<DuckCamo />} />
          </Route>

          {/* Photo Routes */}
          <Route path="photo">
            <Route index element={<Photo />} />
            <Route path="concerts" element={<Concerts />} />
            <Route path="wildlife" element={<Wildlife />} />
            <Route path="swoosh" element={<Nike />} />
            <Route path="hawaii" element={<Hawaii />} />
            <Route path="borrego" element={<Borrego />} />
            <Route path="swoveralls" element={<Swoveralls />} />
          </Route>

          {/* Other Routes */}
          <Route path="video" element={<Video />} />
          <Route path="music" element={<Music />} />
          <Route path="playlists" element={<Playlist />} />
          <Route path="stories" element={<Stories />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
