import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './concerts.css';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import august1 from './music/august1.jpg';
import august2 from './music/august2.jpg';
import august3 from './music/august3.jpg';
import baynk1 from './music/baynk1.jpg';
import baynk2 from './music/baynk2.jpg';
import comatose1 from './music/comatose1.jpg';
import comatose2 from './music/comatose2.jpg';
import goody1 from './music/goody1.jpg';
import jakubi1 from './music/jakubi1.jpg';
import jesse1 from './music/jesse1.jpg';
import limbo1 from './music/limbo1.jpg';
import limbo2 from './music/limbo2.jpg';
import mch1 from './music/mch1.jpg';
import minimansions1 from './music/minimansions1.jpg';
import mnek1 from './music/mnek1.jpg';
import mnek2 from './music/mnek2.jpg';
import moonchild1 from './music/moonchild1.jpg';
import moonchild2 from './music/moonchild2.jpg';
import moonchild3 from './music/moonchild3.jpg';
import neonindian1 from './music/neonindian1.jpg';
import neonindian2 from './music/neonindian2.jpg';
import neonindian3 from './music/neonindian3.jpg';
import nombe1 from './music/nombe1.jpeg';
import nombe2 from './music/nombe2.jpeg';
import ritchie1 from './music/ritchie1.jpg';
import riz1 from './music/riz1.jpg';
import riz2 from './music/riz2.jpg';
import riz3 from './music/riz3.jpg';
import riz4 from './music/riz4.jpg';
import riz5 from './music/riz5.jpg';
import riz6 from './music/riz6.jpg';
import riz7 from './music/riz7.jpg';
import riz8 from './music/riz8.jpg';
import riz9 from './music/riz9.jpg';
import saiah1 from './music/saiah1.jpg';
import saiah2 from './music/saiah2.jpg';
import sampa1 from './music/sampa1.jpg';
import sampa2 from './music/sampa2.jpg';
import sampa3 from './music/sampa3.jpg';
import sampa4 from './music/sampa4.jpg';
import sampa5 from './music/sampa5.jpg';
import sampa6 from './music/sampa6.jpg';
import shae2 from './music/shae2.jpg';
import zhu1 from './music/zhu1.jpg';
import zhu2 from './music/zhu2.jpg';
import zhu3 from './music/zhu3.jpg';
import zhu4 from './music/zhu4.jpg';


const Concerts = () => {
    const [index, setIndex] = useState(-1);
    
    const images = [
        { src: baynk1, id: 'baynk1', alt: 'BAYNK' },
        { src: goody1, id: 'goody1', alt: 'Goody Grace' },
        { src: nombe1, id: 'nombe1', alt: 'NoMBe' },
        { src: shae2, id: 'shae2', alt: 'Shae Brock' },
        { src: sampa3, id: 'sampa3', alt: 'Sampa the Great' },
        { src: august3, id: 'august3', alt: 'August 08' },
        { src: august1, id: 'august1', alt: 'August 08' },
        { src: august2, id: 'august2', alt: 'August 08' },
        { src: zhu4, id: 'zhu4', alt: 'ZHU' },
        { src: nombe2, id: 'nombe2', alt: 'NoMBe' },
        { src: neonindian3, id: 'neonindian3', alt: 'Neon Indian' },
        { src: mnek2, id: 'mnek2', alt: 'MNEK' },
        { src: neonindian2, id: 'neonindian2', alt: 'Neon Indian' },
        { src: ritchie1, id: 'ritchie1', alt: 'Raleigh Ritchie' },
        { src: jakubi1, id: 'jakubi1', alt: 'Jakubi' },
        { src: mch1, id: 'mch1', alt: 'Magic City Hippies' },
        { src: comatose1, id: 'comatose1', alt: 'Brothers Comatose' },
        { src: comatose2, id: 'comatose2', alt: 'Brothers Comatose' },
        { src: limbo1, id: 'limbo1', alt: 'Limbo' },
        { src: limbo2, id: 'limbo2', alt: 'Limbo' },
        { src: mnek1, id: 'mnek1', alt: 'MNEK' },
        { src: baynk2, id: 'baynk2', alt: 'BAYNK' },
        { src: minimansions1, id: 'minimansions1', alt: 'Mini Mansions' },
        { src: neonindian1, id: 'neonindian1', alt: 'Neon Indian' },
        { src: zhu1, id: 'zhu1', alt: 'ZHU' },
        { src: jesse1, id: 'jesse1', alt: 'Jesse' },
        { src: moonchild1, id: 'moonchild1', alt: 'Moonchild' },
        { src: moonchild2, id: 'moonchild2', alt: 'Moonchild' },
        { src: riz1, id: 'riz1', alt: 'RIZ LA VIE' },
        { src: riz2, id: 'riz2', alt: 'RIZ LA VIE' },
        { src: riz3, id: 'riz3', alt: 'RIZ LA VIE' },
        { src: moonchild3, id: 'moonchild3', alt: 'Moonchild' },
        { src: riz8, id: 'riz8', alt: 'RIZ LA VIE' },
        { src: riz9, id: 'riz9', alt: 'RIZ LA VIE' },
        { src: saiah1, id: 'saiah1', alt: 'SAIAH' },
        { src: saiah2, id: 'saiah2', alt: 'SAIAH' },
        { src: zhu2, id: 'zhu2', alt: 'ZHU' },
        { src: riz4, id: 'riz4', alt: 'RIZ LA VIE' },
        { src: riz5, id: 'riz5', alt: 'RIZ LA VIE' },
        { src: riz6, id: 'riz6', alt: 'RIZ LA VIE' },
        { src: riz7, id: 'riz7', alt: 'RIZ LA VIE' },
        { src: sampa1, id: 'sampa1', alt: 'Sampa the Great' },
        { src: sampa2, id: 'sampa2', alt: 'Sampa the Great' },
        { src: sampa4, id: 'sampa4', alt: 'Sampa the Great' },
        { src: sampa5, id: 'sampa5', alt: 'Sampa the Great' },
        { src: sampa6, id: 'sampa6', alt: 'Sampa the Great' },
        { src: zhu3, id: 'zhu3', alt: 'ZHU' }
    ];

    return (
        <>
            <div className="gallery">
                {images.map((image, idx) => (
                    <img 
                        key={idx}
                        src={image.src}
                        onClick={() => setIndex(idx)}
                        alt={image.alt}
                        id={image.id}
                    />
                ))}
            </div>
            <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={images}
            />
        </>
    );
}

export default Concerts